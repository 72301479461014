window.ancoraativa = 0;
window.smoothScroll = {
	velocidade_padrao: 2000,
	easeInOutQuart: (time, from, distance, dutation) => {

		if ((time /= dutation / 2) < 1){

			return distance / 2 * time * time * time * time + from;
		}

		return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
	},
	getScrollTopByHref: element => document.querySelector(element.getAttribute('href')).offsetTop,
	scrollToPosition: (to, dutation) => {
		smoothScroll.smoothScrollTo(0, to, dutation);
	},
	scrollToIdOnClick: (event, dutation) => {

		if(ancoraativa !== 2){

			ancoraativa = 1;

			if(ancoraativa === 1){
				event.preventDefault();
				var to = smoothScroll.getScrollTopByHref(event.currentTarget);
				smoothScroll.scrollToPosition(to, dutation);
			}
		}
	},
	smoothScrollTo: (endX, endY, dutation = smoothScroll.velocidade_padrao, funcaochamada = false) => {

		/* Caso houver um header para descontar a rolagem */
		var header = 0;

		/* Mobile*/
		if(funcaochamada == false && width <= 1023){
			Boss.getById('headerMobile') ? header = ((Boss.getById('headerMobile').clientHeight * -1) + 30) : false;
		}

		/* PC*/
		if(funcaochamada == false && width >= 1024){
			Boss.getById('header_gradient') ? header = ((Boss.getById('header_gradient').clientHeight * -2) + 30) : false;
		}

		var startY = window.scrollY || window.pageYOffset;
		var distanceY = endY - (startY + header);
		var startTime = new Date().getTime();

		var timer = setInterval(function () {

			var time = new Date().getTime() - startTime;
			var newY = smoothScroll.easeInOutQuart(time, startY, distanceY, dutation);

			if (time >= dutation || ancoraativa == 3){

				clearInterval(timer);
				ancoraativa = 0;
			}

			window.scroll(0, newY);
		}, 1000 / 60);
	},
	Position: (obj) => {
		var currenttop = 0;
		if (obj.offsetParent){
			do{
			currenttop += obj.offsetTop;
		}while ((obj = obj.offsetParent));
			return [currenttop];
		}
	},
	go: (element, destino, duration = smoothScroll.velocidade_padrao) => {

		if(ancoraativa !== 2){

			ancoraativa = 1;
			
			if(ancoraativa === 1){
				ancoraativa = 2;

				if(Boss.getById(destino)){
					var yorigem = smoothScroll.Position(element);
					var ydestino = smoothScroll.Position(Boss.getById(destino));

					smoothScroll.smoothScrollTo(yorigem, ydestino, duration);
				}
			}
		}
	},
	goTop: () => {
		if(ancoraativa !== 2){

			ancoraativa = 1;

			if(ancoraativa === 1){
				var yorigem = window.scrollY;
				ancoraativa = 2;
				var ydestino = Boss.getById('push-loader').offsetTop;
				smoothScroll.smoothScrollTo(yorigem, ydestino, this.velocidade_padrao, 'toTop');
			}
		}
	}
};

/* PAUSA O SCROLL - SUAVE QUANDO PRESSIONA AS TECLAS, PAGEDOWN, PAGEUP, SETA CIMA E BAIXO */
document.addEventListener('keydown', function(event){
	var btn = event.keyCode;
	if(btn === 38 || btn === 40 || btn === 35 || btn === 36 || btn === 34 || btn === 33 || btn === 32){
		ancoraativa = 3;
	}
}, false);

/* PAUSA O SCROLL - SUAVE QUANDO O SCROLL DO MOUSE É ACIONADO (usuario tentando usar o scroll) */
document.addEventListener('wheel', () => ancoraativa = 3, false);

/* PAUSA O SCROLL - SUAVE QUANDO O SCROLL DO MOUSE É ACIONADO (usuario tentando usar o scroll) */
document.addEventListener('touchmove', () => ancoraativa = 3, false);